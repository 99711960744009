'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import React, { useEffect, useRef } from 'react';

const sendPageView = (url) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.dataLayer !== 'undefined'
  ) {
    window.dataLayer.push({
      event: 'page_view',
      page: url,
    });
  }
};

export const Analytics = () => {
  const first = useRef(true);

  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (first.current) {
      first.current = false;

      return;
    }

    if (pathname) {
      sendPageView(pathname);
    }
  }, [pathname, searchParams]);

  return process.env.GTM_ID ? (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GTM_ID}`}
          height="0"
          width="0"
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>

      <Script
        id="datalayer-script"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: /* js */ `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              personalization_storage: 'denied',
              analytics_storage: 'denied',
              ad_storage: 'denied',
            });
            gtag('set', 'url_passthrough', false);
            gtag('set', 'ads_data_redaction', true);
          `,
        }}
      />

      <Script
        id="gtm-script"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: /* js */ `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.GTM_ID}');
          `,
        }}
      />
    </>
  ) : null;
};
