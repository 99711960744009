'use client';

import ReactDOM from 'react-dom';

import { PRELOAD_RESOURCES } from '@/config';

export const PreloadResources = () => {
  // @ts-ignore
  ReactDOM.preconnect('https://a.storyblok.com');

  // @ts-ignore
  PRELOAD_RESOURCES.forEach((item) => {
    ReactDOM.preload(item.path, item.params);
  });

  return null;
};
