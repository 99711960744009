'use client';

import React, { createContext, useCallback, useContext, useState } from 'react';

const initialStore = {};

const Store = createContext([{}, {}]);

export const useStore = () => {
  return useContext(Store);
};

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState(initialStore);

  const set = useCallback(
    (name, value) => {
      setStore((state) => {
        const updated = Object.assign({}, state);

        updated[name] = value;

        return updated;
      });
    },
    [setStore],
  );

  const actions = {
    set,
  };

  return <Store.Provider value={[store, actions]}>{children}</Store.Provider>;
};
