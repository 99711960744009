export const CONFIG = {
  title: 'Maison Apicole de Lugos',
  titleSuffix: ' - Maison Apicole de Lugos',

  description: 'Maison Apicole de Lugos',

  shopifyBrand: 'MaisonApicoleLugos',
  productsSlug: 'products',
};

/**
 * @typedef {Object} Locale
 * @property {string} title
 * @property {string} shortName
 * @property {string} locale
 * @property {string} shortCode
 * @property {string} pathPrefix
 */

/**
 * @type {Object<string, Locale>}
 */
export const LOCALE = {
  FR: {
    title: 'FRANÇAIS',
    shortName: 'FR',
    locale: 'fr-fr',
    shortCode: 'fr',
    pathPrefix: '/',
  },
};

/**
 * @type {Locale[]}
 */
export const LOCALES = [LOCALE.FR];

export const DEFAULT_LOCALE = LOCALES[0];

export const PRELOAD_RESOURCES = [
  {
    path: '/font-faces/Montserrat-SemiBold.woff2',
    params: {
      as: 'font',
      crossOrigin: 'true',
      type: 'font/woff2',
    },
  },
];
